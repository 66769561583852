// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1732191161447.1702";
}
// REMIX HMR END

import { useNavigate } from '@remix-run/react';
import { Logo } from '../components/logo';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card';
export default function Index() {
  _s();
  const navigate = useNavigate();
  return <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100">
      {/* Navigation */}
      <nav className="border-b bg-white/50 backdrop-blur-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <span className="ml-2 text-xl font-bold text-gray-900">
                <Logo size="sm" />
              </span>
            </div>
            <div className="flex items-center gap-4">
              <Button onClick={() => {
              navigate('/login');
            }} variant="ghost">
                Log in
              </Button>
              <Button>Book a Demo</Button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
          <h1 className="text-5xl font-bold text-gray-900 mb-6">
            Stay Informed. Stay
            <span className="text-indigo-600"> Balanced.</span>
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Analyze media bias across multiple sources and gain a clearer
            perspective on today's news.
          </p>
          <div className="flex items-center justify-center gap-4">
            <Button size="lg">Book a Demo</Button>
          </div>

          <div className="mt-16">
            <div className="relative">
              <div className="relative w-full max-w-4xl mx-auto">
                <div className="relative bg-white/80 backdrop-blur-lg rounded-2xl shadow-xl overflow-hidden">
                  <img src="https://placehold.co/1200x600" alt="Product screenshot" className="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Everything you need to succeed
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Our platform provides all the tools you need to build, launch, and
            scale your project.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[{
          title: 'Easy Integration',
          description: 'Connect with your favorite tools and services in minutes.',
          icon: '🔌'
        }, {
          title: 'Powerful Analytics',
          description: 'Get insights with real-time data.',
          icon: '📊'
        }, {
          title: 'Team Collaboration',
          description: 'Work together seamlessly with your entire team.',
          icon: '👥'
        }].map((feature, index) => <Card key={index} className="bg-white/80 backdrop-blur-lg">
              <CardHeader>
                <div className="text-3xl mb-4">{feature.icon}</div>
                <CardTitle>{feature.title}</CardTitle>
                <CardDescription>{feature.description}</CardDescription>
              </CardHeader>
            </Card>)}
        </div>
      </div>

      {/* Pricing Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Simple, transparent pricing
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Choose the perfect plan for your needs. Always know what you'll pay.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[{
          title: 'Starter',
          price: '$9',
          description: 'Perfect for individuals',
          features: ['1 User', 'Basic Features', '5GB Storage', 'Email Support']
        }, {
          title: 'Pro',
          price: '$29',
          description: 'Best for small teams',
          features: ['5 Users', 'Advanced Features', '20GB Storage', 'Priority Support', 'API Access']
        }, {
          title: 'Enterprise',
          price: '$99',
          description: 'For larger organizations',
          features: ['Unlimited Users', 'Custom Features', 'Unlimited Storage', '24/7 Support', 'Custom Integration']
        }].map((plan, index) => <Card key={index} className={`bg-white/80 backdrop-blur-lg ${index === 1 ? 'border-indigo-600 border-2' : ''}`}>
              <CardHeader>
                <CardTitle>{plan.title}</CardTitle>
                <div className="mt-2">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className="text-gray-600">/month</span>
                </div>
                <CardDescription>{plan.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  {plan.features.map((feature, featureIndex) => <li key={featureIndex} className="flex items-center gap-2">
                      {/* <Check className="h-5 w-5 text-green-500" /> */}
                      <span>{feature}</span>
                    </li>)}
                </ul>
              </CardContent>
            </Card>)}
        </div>
      </div>

      {/* Footer */}
      <footer className="border-t bg-white/50 backdrop-blur-lg mt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                Product
              </h3>
              <ul className="mt-4 space-y-4">
                {['Features', 'Pricing', 'Security', 'Roadmap'].map(item => <li key={item}>
                    <button className="text-base text-gray-600 hover:text-gray-900">
                      {item}
                    </button>
                  </li>)}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                Company
              </h3>
              <ul className="mt-4 space-y-4">
                {['About', 'Blog', 'Careers', 'Press'].map(item => <li key={item}>
                    <button className="text-base text-gray-600 hover:text-gray-900">
                      {item}
                    </button>
                  </li>)}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                Resources
              </h3>
              <ul className="mt-4 space-y-4">
                {['Documentation', 'Help Center', 'Guides', 'API Status'].map(item => <li key={item}>
                      <button className="text-base text-gray-600 hover:text-gray-900">
                        {item}
                      </button>
                    </li>)}
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                Legal
              </h3>
              <ul className="mt-4 space-y-4">
                {['Privacy', 'Terms', 'Security', 'Cookies'].map(item => <li key={item}>
                    <button className="text-base text-gray-600 hover:text-gray-900">
                      {item}
                    </button>
                  </li>)}
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-8 flex items-center justify-between">
            <p className="text-base text-gray-600">
              © 2024 Ver8. All rights reserved.
            </p>
            <div className="flex space-x-6">
              {['Twitter', 'GitHub', 'LinkedIn'].map(social => <button key={social} className="text-gray-600 hover:text-gray-900">
                  {social}
                </button>)}
            </div>
          </div>
        </div>
      </footer>
    </div>;
}
_s(Index, "CzcTeTziyjMsSrAVmHuCCb6+Bfg=", false, function () {
  return [useNavigate];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;